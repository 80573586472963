import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Socials from '../components/Socials'
import Campaign from "../components/CampaignForm"
import Logo from "../images/SVG/mov3-black.svg"

const Mov3 = () => {

  return(
    <Layout>
      <SEO title="Mov3 Fitness App" />
      <div className="paper py-20 px-5 min-h-screen">
        <div className="container mx-auto my-10">
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 justify-center">
              <img src={Logo} width="125px" className="mx-auto"/>
              <p className="font-light mt-10">Move is a fitness app for people who are looking to live a healthier and happier lifestyle by
              taking care of their mental, physical, and emotional well-being.</p>
              <p className="font-light mt-2">Our app is currently under-development, but when we make it available to the public you will be 
              the first to know by joining our waitlist.</p>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 flex justify-center">
              <Campaign campaign="913155696" cta="Join Waitlist" />
            </div>
          </div>
          <div className="mb-10">
            <Socials />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Mov3